export default {
    setBuildingValue(state, data) {
        state.buildingValue = data;
    },
    setIsTrue(state, data) {
        state.isTrue = data
    },
    setCommentsValue(state, data) {
        state.commentsValue = data;
    },
    setFormValue(state, data) {
        state.formValue = data;
    }
}