export default {
    getBuildingValue(state) {
        return state.buildingValue;
    },
    getIsTure(state) {
        return state.isTrue;
    },
    getCommentsValue(state) {
        return state.commentsValue;
    },
    getFormValue(state) {
        return state.formValue;
    }
}